var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "product-already-in-cart-modal",
            "hide-footer": "",
            "hide-header": "",
            size: "lg",
          },
        },
        [
          _c(
            "div",
            { attrs: { id: "product-already-in-cart-modal-content-div" } },
            [
              _c("div", { staticClass: "mt-4 mb-4" }, [
                _c("h4", [_vm._v(_vm._s(_vm.title))]),
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.sanitizedMessage) },
                }),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }